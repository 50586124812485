body {
    background-color: #f0f0f0;
    background-image: url("/public/img/bg4.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

h1, h2 {
    font-size: 24px;
}

.main-container {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.navbar-logo {
    margin-left: 30px;
}